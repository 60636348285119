import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SitePageService, EventService, SessionService, SurveyService, PosthogService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { Subject, skipWhile, switchMap, takeUntil } from 'rxjs';
declare const fbq: Function;

@Component({
  selector: 'app-quiz-main',
  templateUrl: './quiz-main.component.html',
  styleUrls: ['./quiz-main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuizMainComponent implements OnInit {
  weshapeToken: string;
  quizResponse: any;
  quizSlug: any;
  isMobile: boolean = false;
  deviceInfo: any;
  localhost: boolean = false;
  quizVersionInfo: any;
  unsubscribe$ = new Subject();

  constructor(
    private surveyService: SurveyService,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private router: Router,
    private sitePageService: SitePageService,
    private zone: NgZone,
    private eventService: EventService,
    private deviceService: DeviceDetectorService,
    private posthogService: PosthogService
  ) { }

  ngOnInit(): void {
    this.deviceInfo = this.deviceService.getDeviceInfo()
    var userQuizResponse = this.sessionService.getUserQuizResponse();
    var existingMember: boolean = false;
    if (location.origin.indexOf("localhost") != -1)
      this.localhost = true;
    if (this.route.snapshot.data)
      this.isMobile = this.route.snapshot.data?.isMobile
    if (userQuizResponse) {
      this.weshapeToken = userQuizResponse.weshapeToken;
      if (userQuizResponse.quizComplete) {
        this.sitePageService
          .getSalesPage(userQuizResponse.quizVersionSlug)
          .subscribe((response) => {
            // console.log(response);
            this.router.navigateByUrl(response.res);
          });
        return;
      }

      if (userQuizResponse.profile) {
        existingMember = userQuizResponse.profile.existingMember;
      }
    }

    this.quizSlug = this.sessionService.getQuizVersionSlug() || '';

    // Look in localstorage to see if there is already a quiz session (Handles refreshing the quiz)
    if (!this.quizSlug) {
      this.getQuizVersion();
    }

    this.surveyService.getQuizVersion(this.quizSlug)
      .pipe(
        skipWhile((data) => !data),
        switchMap((data) => {
          this.quizVersionInfo = data;
          let jsonLink = '/dynamic-assets/quiz/quiz-versions/' + data.slug + '.json';
          if (this.localhost) {
            jsonLink = "https://development.weshape.com" + jsonLink;
          }
          return this.surveyService.getQuizData(jsonLink);
        }),
        takeUntil(this.unsubscribe$),
      ).subscribe((response) => {
        if (response) {
          this.quizVersionInfo.questions = response;
          var questionsToSkip: string[] = this.sessionService.getQuizQuestionsToSkip();
          this.skipQuestions(existingMember, questionsToSkip);
          this.quizResponse = this.quizVersionInfo;
        }
      })

    // Enable below code while pointing local quiz-versions for development purpose:
    // this.surveyService.getQuizVersion(this.quizSlug).subscribe((res) => {
    //   const importFile = import("../quiz-data/"+ res.slug);
    //   importFile.then(data => {
    //     this.quizVersionInfo = res;
    //     this.quizVersionInfo.questions = data[res.slug];
    //     var questionsToSkip:string[] = this.sessionService.getQuizQuestionsToSkip();
    //     this.skipQuestions(existingMember, questionsToSkip);
    //     this.quizResponse = this.quizVersionInfo;
    //   });
    // })
  }

  skipQuestions(existingMember, questionsToSkip) {
    let skipQuestions = [];
    // Check if user is skipping question from url
    if (questionsToSkip) {
      skipQuestions.push(...questionsToSkip);
    }
    // if existing member redirected from product or from mobile
    if (this.isMobile || existingMember) {
      skipQuestions.push('names');
      skipQuestions.push('email');
      if (existingMember)
        skipQuestions.push('sms-opt-in');
    }
    // if outside US
    if (!this.eventService.isUS()) {
      skipQuestions.push('sms-opt-in');
    }
    if (skipQuestions.length > 0) {
      this.quizVersionInfo.questions.pages.filter(
        (item) => (skipQuestions && skipQuestions.includes(item['name']))
      ).forEach((element) => {
        element['visible'] = false;
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  onComplete(event: any) {
    // console.log('Quiz has completed..!!');
    // console.log(event);
    if (!this.quizSlug) {
      this.getQuizVersion()
    }
    if (this.weshapeToken) {
      this.surveyService
        .completeQuizResponse(event, this.weshapeToken)
        .subscribe((res) => {
          //console.log(res);
          if (res && res.redirectUrl) {
            this.zone.run(() => {
              fbq('track', 'AddToCart');
              this.sendEvents('quiz completed', { 'quiz_version': this.quizSlug });
              if (this.isMobile) {
                let mobileUrl = '';
                if (res.redirectToProduct)
                  mobileUrl = environment.productUrl + '/app-redirect';
                else
                  mobileUrl = environment.productUrl + '/payment';

                let device = this.deviceInfo['os'];
                //console.log("OS = " + device);

                if (device == 'iOS')
                  mobileUrl = "weshape://open?url=" + mobileUrl;
                else if (device == 'Android')
                  mobileUrl = "weshape://" + mobileUrl;

                //console.log(mobileUrl);
                window.location.href = mobileUrl;
              }
              else if (res.redirectToProduct) {
                window.location.href = res.redirectUrl;
              }
              else {
                this.router.navigate([res.redirectUrl]);
              }

            });
          }
        });
    } else {
      this.sitePageService
        .getSalesPage(this.quizSlug)
        .subscribe((response) => {
          this.zone.run(() => {
            this.sendEvents('quiz completed', { 'quiz_version': this.quizSlug });
            this.router.navigate([response.res]);
          });
        });
    }
  }

  onPartialEvent(data: any) {
    const email = data.email;
    if (this.weshapeToken) {
      this.surveyService
        .updateQuizResponse(data, this.weshapeToken)
        .subscribe((res) => {
          // console.log(res);
        });
    }
  }

  sendEvents(event, eventProperty = {}) {
    this.eventService.sendEvent({
      event: event,
      event_properties: eventProperty
    });
  }

  onCreateNewUser(res) {
    this.weshapeToken = res.res;
    localStorage.setItem('weshape_token', res.res);
    // Add new data layer item to trigger email submit in redtrack
    this.sessionService.addTagManagerEvent({
      event: 'emailSubmitted',
    });

    // Get user's information for PostHogs identify user properties
    let userInfo = JSON.parse(window.localStorage.getItem('weshape-survey'));
    this.posthogService.identifyUser(this.weshapeToken, {
      name: `${userInfo?.names?.first_name} ${userInfo?.names?.last_name}`,
      email: userInfo?.email
    });
  }

  getQuizVersion() {
    let localStorageQuizVersion =
      window.localStorage.getItem('weshape-survey') || null;
    if (localStorageQuizVersion) {
      this.quizSlug = JSON.parse(localStorageQuizVersion)?.versionSlug || '';
      this.sessionService.setQuizVersionSlug(this.quizSlug);
    }
  }
}
