import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Renderer2, Inject, RendererFactory2 } from '@angular/core';
import { BaseService } from './base.service';
import { DOCUMENT } from '@angular/common';
import { catchError, Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class SitePageService extends BaseService {

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private _document: Document,
    private rendererFactory: RendererFactory2,
    private router: Router,
  ) {
    super();
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  private renderer: Renderer2;
  embeddedScripts = [] as any;
  styleScripts = [] as any;
  imageNodes = [] as any;

  loadSitePage<T>(uri: any): Observable<any> {
    uri = btoa(uri);
    const headers = new HttpHeaders().set(
      'timezone',
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    return this.http.get<any>(this.getURL('site/' + uri), {
      headers
    });
  }

  getSalesPage<T>(quizSlug: string): Observable<any> {
    let apiEndpoint = `${environment.apiUrl}/site/sales-page/` + quizSlug;
    return this.http
      .get<any>(apiEndpoint)
      .pipe(catchError((error) => of({ error: error.message })));
  }

  getThirdPartyScripts<T>(): Observable<any> {
    let apiEndpoint = `${environment.apiUrl}/site/3pscripts/`;
    return this.http
      .get<any>(apiEndpoint)
      .pipe(catchError((error) => of({ error: error.message })));
  }

  /**
   * Create the necessary script links to append to the app
   * @param srcLink Script Url
   * @param removeOnExit Removes Script on component destroy
   */
  createScript(srcLink: string, removeOnExit: boolean = true) {
    let documentBody = this._document.body;
    let embedScript = this.renderer.createElement('script') as any;
    embedScript.src = srcLink;
    embedScript.type = 'text/javascript';
    embedScript.async = true;
    embedScript.charset = 'utf-8';
    this.renderer.appendChild(documentBody, embedScript);

    if (removeOnExit)
      this.embeddedScripts.push(embedScript);
  }

  addScript(attributes, removeOnExit: boolean = true) {
    // Create the necessary script links to append to the app
    let documentBody = this._document.body;
    let embedScript = this.renderer.createElement('script') as any;
    embedScript.type = 'text/javascript';
    Object.keys(attributes).forEach(function(key) {
      embedScript.setAttribute(key, attributes[key]);
    })
    embedScript.charset = 'utf-8';
    this.renderer.appendChild(documentBody, embedScript);

    if (removeOnExit)
      this.embeddedScripts.push(embedScript);
  }

  createScriptCSS(srcLink: string, removeOnExit: boolean = true) {
    // Create the necessary script links to append to the app
    // let documentBody = this._document.body;
    let headEl = this._document.head;

    let styleNode = this.renderer.createElement('link') as any;
    styleNode.href = srcLink;
    styleNode.rel = 'stylesheet';
    styleNode.type = 'text/css';
    this.renderer.appendChild(headEl, styleNode);

    if (removeOnExit)
      this.styleScripts.push(styleNode);
  }

  createImageSrc(srcLink: string, removeOnExit: boolean = true) {
    let documentBody = this._document.body;
    let imgNode = this.renderer.createElement('img') as any;
    imgNode.src = srcLink;
    imgNode.width = 0;
    this.renderer.appendChild(documentBody, imgNode);
    
    if(removeOnExit) {
      this.imageNodes.push(imgNode);
    }
  }


  /**
   * Remove all dynamically appended styles and scripts
   */
  removeAllScripts() {
    this.embeddedScripts.forEach((item: any) => this.renderer.removeChild(this._document.body, item));
    this.styleScripts.forEach((item: any) => this.renderer.removeChild(this._document.body, item));
    this.imageNodes.forEach((item: any) => this.renderer.removeChild(this._document.body, item));
  }

  getWWWUrl(uri){
    return environment.wwwUrl + '/' + uri;
  }

  checkAndRedirectUrl(url) {
    if(url.includes('https')) {
      window.location.href = url;
    } else {
      this.router.navigateByUrl(url);
    }
  }
}
