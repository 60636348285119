import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { InlineSVGModule } from 'ng-inline-svg-2';

import { SurveyModule } from "survey-angular-ui";

import { DomService } from '../services/dom.service';

import {
  /** COMPONENTS */
  FooterComponent,
  ProgressBarComponent,
  TabsAccordionComponent,
  HeaderFullComponent,
  FooterMinComponent,
  HeaderMinComponent,
  SurveyComponent,
  TimerComponent,
  ImageSliderComponent,
  RecurlyCreditCardComponent,
  CheckoutChipsComponent,
  PaymentMethodComponent,
  RegistrationFormComponent,
  PaymentComponent,
  UpsellComponent,
  VerifyEmailComponent,
  VideoPlayerComponent,
  PlaylistComponent,
  ScalingScreenComponent,
  LoaderComponent,
  WistiaUploaderComponent,
  ApplePayComponent,
  PostaffiliateComponent,
  PaymentRedirectPurchaseComponent,
  SmsOptinComponent,
  TimerSalesComponent,
  FeelBetterGuaranteeComponent,
  MasterclassReferralInputsComponent, 

  /** MODALS */
  ModalComponent,
  SalesExitIntentModalComponent,

  /** DIRECTIVES */
  CachedSrcDirective,
  DomChangedDirective,
  PhoneMaskDirective,
  ElementInViewDirective,

  /** PIPES */
  SafeHTMLPipe

} from './index';

const SHARED_COMPONENTS = [
  CheckoutChipsComponent,
  PaymentComponent,
  FooterComponent,
  FooterMinComponent,
  HeaderFullComponent,
  HeaderMinComponent,
  ImageSliderComponent,
  PaymentMethodComponent,
  ProgressBarComponent,
  RecurlyCreditCardComponent,
  RegistrationFormComponent,
  SurveyComponent,
  TabsAccordionComponent,
  TimerComponent,
  UpsellComponent,
  VerifyEmailComponent,
  VideoPlayerComponent,
  PlaylistComponent,
  ScalingScreenComponent,
  ModalComponent,
  SalesExitIntentModalComponent,
  LoaderComponent,
  WistiaUploaderComponent,
  ApplePayComponent,
  PostaffiliateComponent,
  PaymentRedirectPurchaseComponent,
  SmsOptinComponent,
  TimerSalesComponent,
  FeelBetterGuaranteeComponent,
  MasterclassReferralInputsComponent
]

const SHARED_DIRECTIVES = [
  CachedSrcDirective,
  DomChangedDirective,
  PhoneMaskDirective,
  ElementInViewDirective
]

const SHARED_PIPES = [
  SafeHTMLPipe
]

@NgModule({
  providers: [ DomService ],
  declarations: [
    SHARED_COMPONENTS,
    SHARED_DIRECTIVES,
    SHARED_PIPES,
    TimerSalesComponent,
    FeelBetterGuaranteeComponent,
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SurveyModule,
    NgbCarouselModule,
    MatProgressSpinnerModule,
    InlineSVGModule.forRoot(),
    MatDialogModule,
    MatTooltipModule,
    NgxSkeletonLoaderModule.forRoot()
  ],
  exports: [
    SHARED_COMPONENTS,
    SHARED_DIRECTIVES,
    SHARED_PIPES
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
