export const environment = {
  env: 'production',
  production: true,
  apiUrl: '/japi',
  thirdPartyTracking: true,
  redTrackUrl: 'https://trk.weshape.com/uniclick.js?defaultcampaignid=639a18f6afa2280001b75431&attribution=lastpaid&regviewonce=false&cookiedomain=weshape.com&cookieduration=60',
  wwwUrl: 'https://www.weshape.com',
  workoutCDN: '/workout-demo',
  productUrl: 'https://my.weshape.com',
};
