<div *ngIf="showConfimation" class="container main text-center mt-4 pt-3 pb-5">
  <div class="top-image img-fluid mb-3">
    <img [src]="verifiedImage" alt="check image" />
  </div>
  <h1 class="inbox-text mb-3 mb-xl-4">Great work!</h1>
  <h2 class="inbox-text mb-3 mb-xl-4">You've earned some points</h2>
  <h4 class="inbox-text mb-3 mb-xl-4">We've sent invitations to the people you've referred!</h4>
  <p class="font-weight-bold mb-3 mb-xl-4">In the meanwhile, please check your email for instructions on getting started with the WeShape Feel Good Challenge!</p>
  <p class="concern-text">If you have questions or concerns, please reach out to the WeCare Team through one of the following options-</p>
  <div class="support">
    <div class="box">Phone: 844-990-2456</div>
    <div class="box">Email: support@weshape.com</div>
  </div>
</div>
<div #referralEl *ngIf="!showConfimation" class="container main text-center pt-3 pb-5">
    <div class="container">
      <div class="top-image img-fluid">
        <img [src]="checkImage" alt="check image" />
      </div>
      <h1>Welcome to the Feel Good Challenge!</h1>
      <h2>Please check your email for confimation + instructions on how to get started with the WeShape Feel Good Challenge! 🎉 </h2>
    </div>

    <div class="container bottom-wrap">
      <p>*If you are not seeing your Confirmation email, please wait just a few minutes and check your Promotions, Spam or any other folder you have set up.</p>
      <p>For assistance, please email us at <a href="mailto:support@weshape.com" title="mailto:support@weshape.com">support@weshape.com</a></p>
    </div>
    <div class="pb-3">
      <!-- <p class="big-line"><span class="big-line-span">OR</span></p> -->
      <p class="big-line"></p>
    </div>
    <div>
      <h2>Want to start earning points right away?</h2>
    </div>
    <div class="bottom-wrap">
      <p>Invite a friend or family member to join you on this Feel Good Challenge and receive 10 points per invite!</p>
      <p>All you have to do is submit your friend or family member's name and email address using the form below and we will make sure they get an invitation to join you!</p>
    </div>


  <div>
    <section class="pb-4">
      <form class="referral-form" [formGroup]="referralForm">
        <ng-container formArrayName="referralGroup" *ngFor="let item of referralGroup.controls; index as i">
          <div class="input-group">
            <ng-container [formGroupName]="i">
              <header>Invite {{ i + 1 }}</header>
              <div>
                <input [ngClass]="{ 'is-invalid': submitted && item?.get('firstName')?.errors }" formControlName="firstName" placeholder="First name" (change)="trimSpaces(item.get('firstName'))"
                >
                <div *ngIf="submitted && item?.get('firstName')?.errors" class="is-invalid-msg">First name is required</div>
              </div>
              <div>
                <input [ngClass]="{ 'is-invalid': submitted && item?.get('email')?.errors }" formControlName="email" placeholder="Email" (change)="trimSpaces(item.get('email'))">
                <div *ngIf="submitted && item?.get('email')?.errors" class="is-invalid-msg">Please enter a valid email address</div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <button class="btn-invite" type="button" [disabled]="maxInviteReached" (click)="addReferralInputs()">
          <span [injectComponent]='true' [inlineSVG]="'assets/images/PlusCircle.svg'"></span> Invite someone else
        </button>
        <button class="submit" type="submit" (click)="submitForm($event)">Submit</button>
      </form>
    </section>
  </div>
  <div class="bottom-wrap">
    <p>Inviting friends to join is not a requirement for participation, simply a way to earn some bonus points. If you are not interested in inviting anyone, you can still participate fully in this Challenge.</p>
    <p>For Full Terms and Conditions of WeShape's Feel Good Challenge, please click <a [href]="getWWWUrl('challenge-rules')" target="_blank">here</a>.</p>
  </div>
</div>
