import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Services / Device and ip Detection
import { DeviceDetectorService } from 'ngx-device-detector';
import { EncryptionService, SessionService, PosthogService, SitePageService } from 'src/app/services';

import { environment } from 'src/environments/environment';
// import amplitude from 'amplitude-js';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
type AnalyticsEvent = {
  event?: string;
  event_properties?: any;
  user_properties?: any;
  user_properties_value?: any;
};

@Injectable({
  providedIn: 'root',
})
export class EventService {
  deviceInfo: any;
  userDetail: any;
  eventData = {};
  // amplitudeKey: string;

  eventAPIKeysBS = new BehaviorSubject<any>(null);
  eventAPIKeys$ = this.eventAPIKeysBS.asObservable();

  usaTimezones = ['America/Adak', 'America/Anchorage', 'America/Boise', 'America/Chicago', 'America/Denver', 'America/Detroit', 'America/Indiana/Indianapolis', 'America/Indiana/Knox', 'America/Indiana/Marengo', 'America/Indiana/Petersburg', 'America/Indiana/Tell_City', 'America/Indiana/Vevay', 'America/Indiana/Vincennes', 'America/Indiana/Winamac', 'America/Juneau', 'America/Kentucky/Louisville', 'America/Kentucky/Monticello', 'America/Los_Angeles', 'America/Menominee', 'America/Metlakatla', 'America/New_York', 'America/Nome', 'America/North_Dakota/Beulah', 'America/North_Dakota/Center', 'America/North_Dakota/New_Salem', 'America/Phoenix', 'America/Sitka', 'America/Yakutat', 'Pacific/Honolulu'];

  constructor(
    private http: HttpClient,
    private deviceService: DeviceDetectorService,
    private sessionService: SessionService,
    private encryptionService: EncryptionService,
    private posthogService: PosthogService,
    private sitePageService: SitePageService
  ) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  HttpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    responseType: 'text' as 'json',
  };

  baseApiUrl = environment.apiUrl;
  env = environment.env;

  //userEmail is optional for marketing events without quizResponse, but email available
  sendEvent(event: AnalyticsEvent, userEmail = null) {
    this.userDetail = this.sessionService.getUserQuizResponse();
    this.eventData['distinct_id'] = this.posthogService.sessionDistinctID; // Posthog - for capturing events on backend
    this.eventData['user_properties'] = {
      email: this.userDetail?.email ?? '',
    };

    // Make a deep copy of eventData to not update the same variable used in all calls
    let dataToSend = JSON.parse(JSON.stringify(this.eventData));


    // Unique Event Data
    if (event['user_properties']) {
      dataToSend['user_properties'][event['user_properties']] = event['user_properties_value'];
    }
    dataToSend['event_type'] = event['event'];
    dataToSend['event_properties'] = event['event_properties'];

    this.posthogService.capture(
      dataToSend['event_type'],
      dataToSend['event_properties'],
      dataToSend['user_properties']
    );
    return of(true);
  }

  /**
   * Create image element with redtrack postback urls for tracking
   * @param event Redtrack Event
   */
  sendRedtrackEvent(event) {
    let redtrackClickId = this.sessionService.getCookie("rtkclickid-store");
    let redtrackUrl = `https://trk.weshape.com/postback?clickid=${redtrackClickId}&type=${event}`;

    // // Tag Manager Test
    // let pageItem = {
    //   "event": event,
    //   "hostname": window.location.hostname,
    //   "url": window.location.href,
    //   "urlPath": window.location.pathname,
    // }


    if(redtrackClickId) {
      // this.sessionService.addTagManagerEvent(pageItem);
      this.sitePageService.createImageSrc(redtrackUrl);
    }
  }

  initEvents() {
    this.getEventApiKey().pipe(map(res => {
      let decryptedString = this.encryptionService.doDecryptString(res.message, 5, 44);
      if (!decryptedString)
        return of(null);
      let parsedString = JSON.parse(decryptedString);
      return parsedString
    })).subscribe(res => {
      this.eventAPIKeysBS.next(res);
    })
  }

  getEventApiKey(): Observable<{ message: string }> {
    // Retrieve Required Keys or Tokens for Payment
    let apiEndpoint = `${this.baseApiUrl}/billing/init-tokens`;
    return this.http.get<any>(apiEndpoint, {
      headers: this.headers,
      responseType: 'text' as 'json'
    }).pipe(
      map((data) => ({ message: data })),
      shareReplay()
    );
  }

  isUS(): boolean {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // return this.usaTimezones.includes(timezone);
    if (this.usaTimezones.includes(timezone))
      return true;
    else
      return false;
  }
}
