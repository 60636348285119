<section #paymentContainer>
  <div *ngIf="showPaymentOptions()" class="payment-type container {{cssClasses}} py-4">
    <legend><span *ngIf="showRegistration || showCheckoutMasterClass">2.</span> Choose Payment Method</legend>
    <div class="card">
      <div class="content-loading" *ngIf="!paymentInit">
        <ngx-skeleton-loader count="4" appearance="line"></ngx-skeleton-loader>
      </div>
      <div class="card-body" [visible]="paymentInit">
        <div class="payment-type-btn">
          <app-apple-pay *ngIf="btToken" [enabled]="paymentItems.apple.enabled" [client]="recurlyClient" [token]="btToken" [productInfo]="productInfo" [termsAccepted]="paymentItems.apple.acceptedTerms" (applePayInit)="updatePaymentInits('apple', $event);" (displayTerms)="displayLegalTerms($event)" (sendApplePayAuth)="sendApplePayAuth($event)"></app-apple-pay>
        </div>
        <div class="payment-type-btn payment-type-paypal">
          <button class="payment-type-paypal-btn" (click)="displayLegalTerms('paypal')"></button>
          <p class="payment-type-paypal-desc">Pay via PayPal; you can pay with your credit card if you don't have PayPal Account.</p>
        </div>
        <div>
          <input type="radio" id="credit" name="sample" value="credit" (change)="onCreditChange($event)" />
          <label class="credit" for="credit">Credit Card</label><img src="/assets/images/quiz/credit-options.svg">
        </div>
        <div class="mt-3" *ngIf="type == 'credit'">
          <form #recurlyPaymentForm [formGroup]="form" (ngSubmit)="onFormSubmit($event)">
            <div class="row">
              <div class="form-group col col-md-6 mb-3">
                <label for="firstName" class="form-label">First Name</label>
                <input type="text" class="form-control" formControlName="fullname" [ngClass]="{ 'is-invalid': submitted && f.fullname.errors }" data-recurly="first_name" id="firstName"
                (change)="trimSpaces(form.get('fullname'))" required="" placeholder="First name*" value="" />
                <div *ngIf="submitted && f.fullname.errors" class="invalid-feedback">
                  <div *ngIf="f.fullname.errors.required">Full name is required</div>
                </div>
              </div>
              <div class="form-group col col-md-6 mb-3">
                <label for="lastName" class="form-label">Last Name</label>
                <input type="text" class="form-control" formControlName="lastname" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" data-recurly="last_name" id="lastName"
                (change)="trimSpaces(form.get('lastname'))" placeholder="Last name*" value="" required="" />
                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                  <div *ngIf="f.lastname.errors.required">Last name is required</div>
                </div>
              </div>
            </div>
            <app-recurly-credit-card *ngIf="creditCardInitialize" [formSubmitted]="submitted" [client]="recurlyClient" [elements]="recurlyElements" (recurlyFieldsState)="checkValidCCInfo($event)"></app-recurly-credit-card>
            <div class="row">
              <p>Billing Address</p>
              <div class="form-group col col-12 mb-3">
                <input type="tel" class="form-control" *ngIf="showMobileTerms()" appPhoneMask [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" formControlName="phone" name="phone" data-recurly="phone" value="" placeholder="Phone: (__) ___-___">
                <input type="tel" class="form-control" *ngIf="!showMobileTerms()" formControlName="phone" name="phone" data-recurly="phone" value="" placeholder="Phone">
                <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                  <div *ngIf="f.phone.errors.required">Please enter phone number to continue.</div>
                </div>
              </div>
              <div class="form-group col col-12 mb-3">
                <input type="text" class="form-control" formControlName="address1" [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" name="address1" data-recurly="address1"
                (change)="trimSpaces(form.get('address1'))" value="" placeholder="Address*">
                <div *ngIf="submitted && f.address1.errors" class="invalid-feedback">
                  <div *ngIf="f.address1.errors.required">Please enter your billing address.</div>
                </div>
              </div>
              <div class="form-group col col-12 mb-3">
                <input type="text" class="form-control" formControlName="address2" [ngClass]="{ 'is-invalid': submitted && f.address2.errors }" name="address2"
                (change)="trimSpaces(form.get('address2'))" data-recurly="address2" value="" placeholder="Apartment, suite, unit, etc. (optional)">
              </div>
              <div class="form-group col col-12 mb-3">
                <input type="text" class="form-control" formControlName="city" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" name="city"
                (change)="trimSpaces(form.get('city'))" data-recurly="city" value="" placeholder="City*">
                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                  <div *ngIf="f.city.errors.required">City is required.</div>
                </div>
              </div>
              <div class="form-group col col-12 col-md-4 mb-3">
                <select class="form-select" formControlName="country" (change)="countrySelection()" [ngClass]="{ 'is-invalid': submitted && f.country.errors }" data-recurly="country" id="country" name="country" required="">
                  <option value="">Country</option>
                  <option *ngFor="let option of country" [value]="option.code">
                    {{option.name}}
                  </option>
                </select>
                <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                  <div *ngIf="f.country.errors.required">Country is required</div>
                </div>
              </div>
              <div class="form-group col col-6 col-md-4 mb-3">
                <ng-container *ngIf="selectedUSCountry; else stateInputField">
                  <select class="form-select" formControlName="state" [ngClass]="{ 'is-invalid': submitted && f.state.errors }" data-recurly="state" id="state" name="state" required="">
                    <option *ngFor="let option of states" [value]="option.value">
                      {{option.name}}
                    </option>
                  </select>
                </ng-container>
                <ng-template #stateInputField>
                  <input type="text" class="form-control" formControlName="state" [ngClass]="{ 'is-invalid': submitted && f.state.errors }" name="state" data-recurly="state"
                  (change)="trimSpaces(form.get('state'))" placeholder="State/Province">
                </ng-template>
                <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                  <div *ngIf="f.state.errors.required">State is required</div>
                </div>
              </div>
              <div class="form-group col col-6 col-md-4 mb-3">
                <input type="text" formControlName="postalCode" [ngClass]="{ 'is-invalid': submitted && f.postalCode.errors }" class="form-control" name="postal_code" data-recurly="postal_code"
                (change)="trimSpaces(form.get('postalCode'))" value="" placeholder="Postal Code*">
                <div *ngIf="submitted && f.postalCode.errors" class="invalid-feedback">
                  <div *ngIf="f.postalCode.errors.required">Zip code is required.</div>
                </div>
              </div>
            </div>
            <div class="form-group form-check checkout" *ngIf="showMobileTerms()">
              <input type="checkbox" name="acceptTermsMobile" id="acceptTermsMobile" formControlName="acceptTermsMobile" class="form-check-input checkout-tick" [ngClass]="{ 'is-invalid': submitted && f.acceptTermsMobile.errors }" />
              <label for="acceptTermsMobile" class="form-check-label checkout-terms">I would like to receive text notifications for workout reminders, product updates and special offers from WeShape.
                Msg & data rates may apply. Reply HELP for help or STOP to cancel. View our Privacy policy and Terms and Conditions.</label>
              <div *ngIf="submitted && f.acceptTermsMobile.errors" class="invalid-feedback">
                Mobile Accept Terms is required
              </div>
            </div>
            <div class="form-group form-check checkout">
              <input type="checkbox" formControlName="acceptTerms" class="form-check-input checkout-tick" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" />
              <label for="acceptTerms" class="form-check-label checkout-terms">{{ productInfo.legal }}</label>
              <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">
                Accept Terms is required
              </div>
            </div>

            <div class="button-container">
              <button type="submit" class="payment-type-signup mt-3" [disabled]="loading">
                <span class="payment-type-loading" *ngIf="loading">
                  <mat-spinner diameter="20" strokeWidth="4"></mat-spinner>
                </span>Sign Up</button>
            </div>
            <!-- Recurly.js will update this field automatically -->
            <input type="hidden" name="recurly-token" data-recurly="token">
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="terms-overlay" *ngIf="displayTerms.state">
  <div class="popup">
    <div class="content">
      <input type="checkbox" class="form-check-input checkout-tick" (change)="termsAccepted(displayTerms.type)" />
      <label class="form-check-label checkout-terms">{{ productInfo.legal }}</label>
    </div>
    <a class="popup-back" (click)="resetLegalTerms()">Back to Checkout</a>
  </div>
</div>
